<template>
  <Panel>
    <template #title><span>Shipment Measure</span></template>
    <template #content>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :label-position="labelPosition"
      >
        <el-form-item label="Tracking id" prop="tracking_id" class="first-item">
          <el-input
            type="text"
            v-model="ruleForm.tracking_id"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Length(IN)" prop="length">
          <el-input
            type="text"
            v-model="ruleForm.length"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Width(IN)" prop="width">
          <el-input
            type="text"
            v-model="ruleForm.width"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Height(IN)" prop="height">
          <el-input
            type="text"
            v-model="ruleForm.height"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Weight(LB)" prop="weight">
          <el-input
            type="text"
            v-model="ruleForm.weight"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :disabled="flag"
            >Submit</el-button
          >
          <el-button @click="resetForm('ruleForm')">Reset</el-button>
        </el-form-item>
      </el-form>
    </template>
  </Panel>
</template>

<script>
import { shipmentmeasure } from '../../api/user'
export default {
  data() {
    var validatval = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please fill in.'))
      } else {
        callback()
      }
    }
    return {
      flag: false,
      labelPosition: 'right',
      ruleForm: {
        tracking_id: '',
        length: '',
        width: '',
        height: '',
        weight: ''
      },
      rules: {
        tracking_id: [{ validator: validatval, trigger: 'blur' }],
        length: [{ validator: validatval, trigger: 'blur' }],
        width: [{ validator: validatval, trigger: 'blur' }],
        height: [{ validator: validatval, trigger: 'blur' }],
        weight: [{ validator: validatval, trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm(formName) {
      if (this.flag) {
        return
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.flag = true
          const data = await shipmentmeasure(this.ruleForm)
          if (data.status === 200) {
            this.$message({
              message: 'Submit successful',
              type: 'success'
            })
            this.$refs[formName].resetFields()
            // console.log(this.flag)
            setTimeout(() => {
              this.flag = false
            }, 2000)
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
            setTimeout(() => {
              this.flag = false
            }, 3000)
          }
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.first-item {
  margin-top: 20px;
}
.el-form-item {
  margin-bottom: 40px;
}
</style>
